import React from 'react';
import { Switch } from 'react-router-dom';

import AutocompleteAddress from '~/pages/Addresses/AutocompleteAddress';
import ConfirmAddress from '~/pages/Addresses/ConfirmAddress';
import ConfirmAddressGPS from '~/pages/Addresses/ConfirmAddressGPS';
import ConfirmAddressNeighborhood from '~/pages/Addresses/ConfirmAddressNeighborhood';
import ListAddress from '~/pages/Addresses/ListAddress';
import AdvertiserDetails from '~/pages/AdvertiserDetails';
import AdvertiserMenu from '~/pages/AdvertiserMenu';
import AdvertiserMenuSearch from '~/pages/AdvertiserMenuSearch';
import Bag from '~/pages/Bag';
import ChangeContacts from '~/pages/ChangeContacts';
import ChangeInfo from '~/pages/ChangeInfo';
import ItemDetails from '~/pages/ItemDetails';
import LoyaltyCardDetail from '~/pages/LoyaltyCardDetail';
import MyLoyaltyCards from '~/pages/MyLoyaltyCards';
import MyOrders from '~/pages/MyOrders';
import MyProfile from '~/pages/MyProfile';
import OrderReview from '~/pages/OrderReview';
import OrderItemDetails from '~/pages/OrderReview/OrderItemDetails';
import SelectOrderSchedule from '~/pages/OrderReview/SelectOrderSchedule';
import SelectPaymentMethod from '~/pages/OrderReview/SelectPaymentMethod';
import Page404 from '~/pages/Page404';
import PixPendingPayment from '~/pages/PixPendingPayment';
import PixPaymentHelp from '~/pages/PixPendingPayment/PixPaymentHelp';
import ViewOrder from '~/pages/ViewOrder';
import OrderDetails from '~/pages/ViewOrder/OrderDetails';
import ReOrder from '~/pages/ViewOrder/ReOrder';

import {
  advertiserDetails,
  advertiserMenu,
  advertiserMenuSearch,
  autocompleteAddress,
  bag,
  changeContacts,
  changeInfo,
  confirmAddress,
  confirmAddressGPS,
  confirmAddressNeighborhood,
  itemDetails,
  itemDetailsWithId,
  listAddress,
  loyaltyCardDetail,
  myLoyaltyCards,
  myOrders,
  myProfile,
  orderDetails,
  orderItemDetails,
  orderReview,
  pixPaymentHelp,
  pixPendingPayment,
  reOrder,
  selectOrderSchedule,
  selectPaymentMethod,
  viewOrder,
} from './routeMap';
import Route from './RouteWrapper';

const Routes: React.FC = () => {
  return (
    <Switch>
      {/* Advertiser */}
      <Route path={advertiserMenu} exact component={AdvertiserMenu} />
      <Route path={advertiserDetails} exact component={AdvertiserDetails} />
      <Route path={advertiserMenuSearch} exact component={AdvertiserMenuSearch} />

      {/* Product Detail, Loyalty Card */}
      <Route path={itemDetailsWithId} exact component={ItemDetails} />
      <Route path={itemDetails} component={ItemDetails} />
      <Route isPrivate path={myLoyaltyCards} exact component={MyLoyaltyCards} />
      <Route isPrivate path={loyaltyCardDetail} exact component={LoyaltyCardDetail} />

      {/* Order */}
      <Route isPrivate path={myOrders} exact component={MyOrders} />
      <Route path={bag} exact component={Bag} />
      <Route path={orderReview} exact component={OrderReview} />
      <Route path={selectOrderSchedule} exact component={SelectOrderSchedule} />
      <Route path={selectPaymentMethod} exact component={SelectPaymentMethod} />
      <Route path={orderItemDetails} exact component={OrderItemDetails} />
      <Route isPrivate path={viewOrder} exact component={ViewOrder} />
      <Route isPrivate path={orderDetails} exact component={OrderDetails} />
      <Route path={reOrder} exact component={ReOrder} />
      <Route isPrivate path={pixPendingPayment} exact component={PixPendingPayment} />
      <Route isPrivate path={pixPaymentHelp} exact component={PixPaymentHelp} />

      {/* Profile and Account */}
      <Route isPrivate path={myProfile} exact component={MyProfile} />
      <Route isPrivate path={changeContacts} exact component={ChangeContacts} />
      <Route isPrivate path={changeInfo} exact component={ChangeInfo} />

      {/* Addresses */}
      <Route isPrivate path={confirmAddress} exact component={ConfirmAddress} />
      <Route
        isPrivate
        path={confirmAddressNeighborhood}
        exact
        component={ConfirmAddressNeighborhood}
      />
      <Route isPrivate path={confirmAddressGPS} exact component={ConfirmAddressGPS} />
      <Route isPrivate path={listAddress} exact component={ListAddress} />
      <Route isPrivate path={autocompleteAddress} exact component={AutocompleteAddress} />

      {/* Pagina 404 */}
      <Route path="/" component={Page404} />
    </Switch>
  );
};

export default Routes;
