import { Text } from '@chakra-ui/core';
import React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Address from '~/models/Address';
import DeliveryType from '~/models/types/DeliveryType';
import { RootState } from '~/store';
import UserActions from '~/store/ducks/user';
import { formatCurrencyBRL } from '~/utils/currency';
import { getDistanceBetweenAddresses, getRouteDistanceBetweenAddresses } from '~/utils/location';

const { setLastCalculatedAddress, setHasUpdatedTheAddress } = UserActions;

export interface DynamicDeliveryParams {
  deliveryFee?: number;
  deliveryDynamicFee?: number;
  deliveryDynamicFeeMaxDist?: number;
  deliveryDynamicFeeExceededAmount?: number;
  deliveryDynamicFeeFreeDist: number;
  advertiserAddress: Address;
  userAddress: Address;
  deliveryType: DeliveryType;
}

export enum DeliveryTypes {
  DYNAMIC = 'dynamic',
  FREE = 'free',
  UNIQUE = 'unique',
}

function useDynamicDeliveryFee({
  deliveryFee,
  deliveryDynamicFee,
  deliveryDynamicFeeMaxDist,
  deliveryDynamicFeeExceededAmount,
  deliveryDynamicFeeFreeDist,
  userAddress,
  advertiserAddress,
  deliveryType,
}: DynamicDeliveryParams) {
  const [totalDistanceInMeters, setTotalDistanceInMeters] = useState(0);
  const [canFinishOrder, setCanFinishOrder] = useState(true);
  const isLoggedin = useSelector((state: RootState) => state.auth?.isLoggedin);
  const feeCalcType = useSelector(
    (state: RootState) => state.advertiser?.advertiserInfo?.delivery_dynamic_fee_calc_type,
  );
  const deliveryTakeawayConfig = useSelector(
    (state: RootState) => state.advertiser?.advertiserInfo?.deliveryTakeawayConfig,
  );
  const neighborhoodAreaConfigs = useSelector(
    (state: RootState) => state.advertiser?.advertiserInfo?.neighborhoodAreaConfigs,
  );
  const advertiserHasUpdatedTheAddress = useSelector(
    (state: RootState) => state.user.hasUpdatedTheAddress,
  );

  const currentAddress = useSelector((state: RootState) => state.addresses?.currentAddress);
  const lastCalculatedAddress = useSelector(
    (state: RootState) => state.user?.lastCalculatedAddress,
  );
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const getFeeType = useMemo(() => {
    if (deliveryFee === 0 && deliveryDynamicFee !== 0) {
      return DeliveryTypes.DYNAMIC;
    }

    if (deliveryFee === 0 && deliveryDynamicFee === 0) {
      return DeliveryTypes.FREE;
    }

    if (deliveryFee !== 0 && deliveryDynamicFee === 0) {
      return DeliveryTypes.UNIQUE;
    }
  }, [deliveryDynamicFee, deliveryFee]);

  const calculateTotalDistanceInMeters = useCallback(async () => {
    if (!isLoggedin) {
      setTotalDistanceInMeters(0);
      return;
    }
    if (
      advertiserAddress &&
      userAddress &&
      !deliveryTakeawayConfig?.is_using_neighborhood_area && // Neighborhood area config
      deliveryTakeawayConfig?.is_using_range_area // Range area config
    ) {
      setLoading(true);
      try {
        // Force not delivery to the address, when the dynamic delivery fee calculation
        // type (route or radius) was not filled at advertiser data
        let distance = 999999;
        // If address is associated with neighborhood (lat and lng is null)
        // Get the distance based on google routing
        if (userAddress?.dne_district_id) {
          setTotalDistanceInMeters(distance);
          setCanFinishOrder(false);
          return;
        }

        if (feeCalcType === 'route') {
          // Only calculate the distance with google, when the address was changed
          if (
            advertiserHasUpdatedTheAddress ||
            lastCalculatedAddress?.address?.latitude.toString() !==
              userAddress?.latitude.toString() ||
            lastCalculatedAddress?.address?.longitude.toString() !==
              userAddress?.longitude.toString()
          ) {
            // Store the current address before get info from google, to avoiding
            // new requests to google even have an existing one running
            dispatch(
              setLastCalculatedAddress({
                address: userAddress,
                distanceToAdvertiser: null,
              }),
            );
            // Get the calculated distance with google
            distance = await getRouteDistanceBetweenAddresses(
              { lat: advertiserAddress?.latitude, lng: advertiserAddress?.longitude },
              { lat: userAddress?.latitude, lng: userAddress?.longitude },
            );

            // Store the current address and the calculated distance
            dispatch(
              setLastCalculatedAddress({
                address: userAddress,
                distanceToAdvertiser: distance,
              }),
            );
            dispatch(setHasUpdatedTheAddress(false));
          }
          // If the address is the same, use the stored distance
          else {
            distance = lastCalculatedAddress?.distanceToAdvertiser;
          }
        } else if (feeCalcType === 'radius') {
          // Get the distance based on the radius
          distance = getDistanceBetweenAddresses(advertiserAddress, userAddress);
        } else {
          throw new Error('Erro ao calcular taxa de entrega.');
        }

        setTotalDistanceInMeters(distance);
        setCanFinishOrder(true);
      } catch (err) {
        toast.error('Ocorreu um erro ao calcular sua taxa de entrega.');
        setCanFinishOrder(false);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [
    advertiserAddress,
    deliveryTakeawayConfig,
    userAddress,
    isLoggedin,
    feeCalcType,
    advertiserHasUpdatedTheAddress,
  ]);

  useEffect(() => {
    if (
      deliveryType === 'delivery' &&
      !deliveryTakeawayConfig?.is_using_neighborhood_area &&
      deliveryTakeawayConfig?.is_using_range_area
    ) {
      calculateTotalDistanceInMeters();
    } else {
      setLoading(false);
    }
  }, [
    deliveryType,
    calculateTotalDistanceInMeters,
    deliveryTakeawayConfig,
    advertiserHasUpdatedTheAddress,
  ]);

  const getDeliveryValue = useMemo(() => {
    let deliveryValue: number;

    if (
      deliveryTakeawayConfig &&
      deliveryTakeawayConfig?.is_using_neighborhood_area &&
      !deliveryTakeawayConfig?.is_using_range_area
    ) {
      if (!currentAddress) {
        setCanFinishOrder(deliveryType === 'take_away');
        return 0;
      }

      const neighborhoodAreaConfig = neighborhoodAreaConfigs.find(
        (n) =>
          n.district?.name?.toLowerCase() === currentAddress?.neighborhood?.toLowerCase() &&
          n.district?.uf?.toLowerCase() === currentAddress?.state?.toLowerCase() &&
          n.district?.id === currentAddress?.dne_district_id,
      );
      setCanFinishOrder(() => !!neighborhoodAreaConfig);
      let actualDeliveryFee = neighborhoodAreaConfig
        ? neighborhoodAreaConfig.delivery_fee
        : deliveryFee;
      return deliveryType === 'take_away' ? 0 : actualDeliveryFee;
    }

    switch (getFeeType) {
      case DeliveryTypes.FREE:
      case DeliveryTypes.UNIQUE:
        return deliveryType === 'take_away' ? 0 : deliveryFee;
      case DeliveryTypes.DYNAMIC:
        if (
          !userAddress ||
          !advertiserAddress ||
          !userAddress.latitude ||
          !userAddress.longitude ||
          !advertiserAddress.longitude ||
          !advertiserAddress.longitude ||
          !totalDistanceInMeters
        ) {
          return 0;
        }

        const totalDistanceInKm = Math.ceil(totalDistanceInMeters / 1000);

        if (totalDistanceInKm <= deliveryDynamicFeeFreeDist) {
          deliveryValue = 0;
        } else if (totalDistanceInKm <= deliveryDynamicFeeMaxDist) {
          deliveryValue = deliveryDynamicFee;
        } else {
          const exceedingKm = totalDistanceInKm - deliveryDynamicFeeMaxDist;
          const exceedingDeliveryValue = exceedingKm * deliveryDynamicFeeExceededAmount;
          deliveryValue = deliveryDynamicFee + exceedingDeliveryValue;
        }

        return deliveryValue;
      default:
        break;
    }
  }, [
    userAddress,
    deliveryTakeawayConfig,
    advertiserAddress,
    deliveryFee,
    getFeeType,
    deliveryDynamicFee,
    deliveryDynamicFeeMaxDist,
    deliveryDynamicFeeExceededAmount,
    deliveryDynamicFeeFreeDist,
    totalDistanceInMeters,
    deliveryType,
    neighborhoodAreaConfigs,
    currentAddress,
  ]);

  const getDeliveryDescription = useMemo(() => {
    const deliveryValue = getDeliveryValue;
    if (
      deliveryTakeawayConfig &&
      deliveryTakeawayConfig?.is_using_neighborhood_area &&
      !deliveryTakeawayConfig?.is_using_range_area
    ) {
      if (!currentAddress) {
        return `Selecione seu endereço`;
      }
      return deliveryValue > 0 ? formatCurrencyBRL(deliveryValue) : `Grátis`;
    }
    switch (getFeeType) {
      case DeliveryTypes.FREE:
        return 'Grátis';
      case DeliveryTypes.UNIQUE:
        return `${formatCurrencyBRL(deliveryFee)}`;
      case DeliveryTypes.DYNAMIC:
        if (deliveryDynamicFeeFreeDist && !deliveryValue) {
          return `Grátis até ${deliveryDynamicFeeFreeDist}km.`;
        } else if (!deliveryDynamicFeeFreeDist && !deliveryValue) {
          return `À partir de ${formatCurrencyBRL(deliveryDynamicFee)}`;
        }
        return formatCurrencyBRL(deliveryValue);
      default:
        return '';
    }
  }, [
    deliveryFee,
    getDeliveryValue,
    getFeeType,
    deliveryDynamicFee,
    deliveryDynamicFeeFreeDist,
    deliveryTakeawayConfig,
    currentAddress,
  ]);

  const getPrettyDeliveryDescription = useMemo(() => {
    if (
      deliveryTakeawayConfig &&
      deliveryTakeawayConfig?.is_using_neighborhood_area &&
      !deliveryTakeawayConfig?.is_using_range_area
    ) {
      const sortedValues = neighborhoodAreaConfigs
        .map((n) => n.delivery_fee)
        .sort((prev, next) => (prev > next ? -1 : 1));
      const deliveryValue = sortedValues.pop();
      return (
        <Text as="span" fontSize="sm" fontWeight="400" color="gray.400">
          A partir de {formatCurrencyBRL(deliveryValue)}
        </Text>
      );
    }
    switch (getFeeType) {
      case DeliveryTypes.FREE:
        return (
          <Text as="span" fontSize="sm" fontWeight="400" color="gray.400">
            Grátis
          </Text>
        );
      case DeliveryTypes.UNIQUE:
        return (
          <Text as="span" fontSize="sm" fontWeight="400" color="gray.400">
            {formatCurrencyBRL(deliveryFee)}
          </Text>
        );
      case DeliveryTypes.DYNAMIC:
        if (deliveryDynamicFeeFreeDist) {
          return (
            <Text as="span" fontSize="sm" fontWeight="400" color="gray.400">
              Grátis até{' '}
              <Text as="span" color="gray.700">
                {deliveryDynamicFeeFreeDist}km
              </Text>
            </Text>
          );
        } else if (!deliveryDynamicFeeFreeDist) {
          return (
            <Text as="span" fontSize="sm" fontWeight="400" color="gray.400">
              À partir de{' '}
              <Text as="span" color="gray.700">
                {formatCurrencyBRL(deliveryDynamicFee)}
              </Text>
            </Text>
          );
        }
        return (
          <Text as="span" fontSize="sm" fontWeight="400" color="gray.400">
            {formatCurrencyBRL(getDeliveryValue)}
          </Text>
        );
      default:
        return '';
    }
  }, [
    deliveryFee,
    getDeliveryValue,
    getFeeType,
    deliveryDynamicFee,
    deliveryTakeawayConfig,
    neighborhoodAreaConfigs,
    deliveryDynamicFeeFreeDist,
  ]);

  return {
    feeType: getFeeType,
    value: getDeliveryValue,
    description: getDeliveryDescription,
    prettyDescription: getPrettyDeliveryDescription,
    loading,
    canFinishOrder,
  };
}

export default useDynamicDeliveryFee;
